.home-page {
    overflow: hidden;
    background-color: #000000;
    background-image: url('../../../assets/images/swiper-bg/PC-Background.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 0px;
    .nav_top {
        top: 0;
        width: 100%;
        height: 47px;
        background: #000000;
        border-radius: 0px 0px 0px 0px;
        .top-tit {
            font-family: NeuethingSansTest-Bold;
            font-weight: normal;
            font-size: 12px;
            color: #ffffff;
            line-height: 32px;
            letter-spacing: 0.8px;
            .join_text {
                text-decoration: underline;
                margin-left: 5px;
                cursor: pointer;
            }
            .arrowUpRight-img {
                width: 18px;
                margin-left: 15px;
            }
        }
    }
    .content-box {
        // padding: 0 40px;
        min-height: 1000px;
        position: relative;
        .join_btn {
            width: 165px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-right: 5px;
            border-radius: 12px;
        }
        .contentTop_box {
            padding: 21px 0 0 0;
            top: 0;
            position: absolute;
            left: 40px;
            width: calc(100% - 80px);
            display: flex;
            justify-content: space-between;
            z-index: 10;
            .logo {
                width: 173px;
            }
            .join_btn_top {
                width: 142px;
                font-size: 11px;
                font-family: NeuethingSansTest-SemiBoldExpanded;
                font-weight: normal;
                border-radius: 12px;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                height: 36px;
                background-color: #000000;
                color: #dcff00;
            }
            @media only screen and (max-width: 1400px) {
                padding: 21px 0 170px 0;
            }
            @media only screen and (max-width: 1000px) {
                padding: 21px 0 100px 0;
            }
        }
        .contentTop_textView {
            position: relative;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
            // padding-left: 95px;
            // padding-right: 63px;
            // width: 1361px;
            // margin: 0 auto;
            .topLeft_img {
                width: 361px;
                margin-bottom: 18px;
            }
            .contentTop_textRight {
                // margin-left: auto;
                position: absolute;
                width: 380px;
                z-index: 10;
                // bottom: 100px;
                top: 30%;
                right: 130px;
                .top-tit {
                    font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                    font-weight: normal;
                    font-size: 31px;
                    color: #ffffff;
                    line-height: 43px;
                    text-align: left;
                }
                .top-tip {
                    width: 377px;
                    font-family: NeuethingSansTest-RegularExpanded;
                    font-weight: normal;
                    font-size: 20px;
                    color: #a6a6a6;
                    line-height: 29px;
                    text-align: left;
                    margin: 27px 0 18px 0;
                }
                .top-joinBox {
                    font-family: NeuethingSansTest;
                    font-weight: 400;
                    font-size: 14px;
                    color: #a6a6a6;
                    line-height: 20px;
                    text-align: left;
                    .verterLine-box {
                        width: 1px;
                        height: 22px;
                        background: rgba(255, 255, 255, 0.3);
                        margin: 0 20px;
                    }
                }
            }
            @media only screen and (max-width: 1400px) {
                width: 100%;
                // padding: 0 40px;
                .contentTop_textRight {
                    right: 40px;
                }
            }
            @media only screen and (max-width: 1000px) {
                width: 100%;
                padding: 0px;
                .contentTop_textRight {
                    right: 20px;
                }
            }
        }
        .content_newBox {
            padding: 0 40px;
        }
        .top-clubsBox {
            margin-top: 290px;
            .club_tip {
                text-align: center;
                font-family: NeuethingSansTest-Meduim;
                font-weight: 400;
                font-size: 8px;
                color: #ffffff;
                line-height: 20px;
                margin-bottom: 32px;
                opacity: 0.7;
            }
            .clubs_img {
                width: 110px;
                height: 110px;
                margin-right: 35px;
            }
            @media only screen and (max-width: 1400px) {
                margin-top: 200px;
            }
            @media only screen and (max-width: 1000px) {
                margin-top: 100px;
            }
        }
        .works-box {
            margin-top: 190px;
            padding-bottom: 127px;
            border-bottom: 1px solid #333333;
            .feature_text {
                font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                font-weight: normal;
                font-size: 11px;
                color: #dcff00;
                line-height: 13px;
                letter-spacing: 1px;
                text-align: center;
            }
            .works_tit {
                font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                font-weight: normal;
                font-size: 47px;
                color: #ffffff;
                line-height: 56px;
                text-align: center;
                margin: 17px 0 40px 0;
            }
            .works_tip {
                font-family: NeuethingSansTest-RegularExpanded;
                font-weight: normal;
                font-size: 20px;
                color: #a6a6a6;
                line-height: 29px;
                text-align: center;
            }
            .worksItemBox {
                align-items: flex-start;
                width: 1361px;
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin: 0 auto;
                margin-top: 55px;
                justify-content: space-between;
                display: grid;
                grid-template-columns: repeat(3,1fr);
                grid-column-gap: 23px;
                .worksItem {
                    &:nth-child(2) {
                        margin: 0 !important;
                    }
                    .works_img {
                        width: 100% !important;
                        height: 100% !important;
                        min-height: 400px;
                    }
                    .workStep_text {
                        padding: 0 11px;
                    }
                    .works_step {
                        font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                        font-weight: normal;
                        font-size: 24px;
                        color: #ffffff;
                        line-height: 33px;
                        text-align: left;
                        margin-top: 80px;
                        .stepNum_box {
                            min-width: 40px;
                            min-height: 40px;
                            max-height: 40px;
                            line-height: 44px;
                            text-align: center;
                            background: #000000;
                            border-radius: 50%;
                            border: 1px solid #232323;
                            color: #dcff00;
                            font-family: NeuethingSansTest-MediumExpanded;
                            margin-right: 20px;
                            font-size: 19px;
                            margin-bottom: 5px;
                        }
                    }
                    .works_tips {
                        margin-top: 22px;
                        font-family: NeuethingSansTest;
                        font-weight: 400;
                        font-size: 18px;
                        color: #878787;
                        line-height: 28px;
                        text-align: left;
                        .AssistantLogo_img {
                            width: 54px;
                            margin-left: 5px;
                            transform: translateY(8px);
                        }
                    }
                }
                @media only screen and (max-width: 1400px) {
                    width: 100%;
                    padding: 0 40px;
                    .worksItem {
                        // flex: 1;
                        // width: calc(100% / 3);
                        // min-width: calc(100% / 3);
                        .works_img {
                            width: 100%;
                            height: auto;
                            min-height: 290px;
                            // aspect-ratio: 1;
                        }

                    }
                }
                @media only screen and (max-width: 1000px) {
                    width: 100%;
                    padding: 0px;
                    .works_img {
                        width: 100%;
                        height: auto;
                        min-height: 290px;
                        // aspect-ratio: 1;
                    }
                    .works_tips{
                        margin-bottom: 40px;
                    }
                }
            }
            @media only screen and (max-width: 1400px) {
                margin-top: 150px;
            }
            @media only screen and (max-width: 1000px) {
                margin-top: 100px;
            }
        }
        .actionBox {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 1361px;
            padding: 110px 0 127px 0 !important; 
            margin: 0 auto;
            border-bottom: 1px solid #333333;
            .actionLeft_box {
                padding: 14px 0 0px 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                .action_tit {
                    font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                    font-weight: normal;
                    font-size: 47px;
                    color: #ffffff;
                    line-height: 56px;
                    // width: 408px;
                }
                .action_tip {
                    margin-top: 50px;
                    width: 521px;
                    font-family: NeuethingSansTest-RegularExpanded;
                    font-weight: normal;
                    font-size: 20px;
                    color: #a6a6a6;
                    line-height: 29px;
                }
                .video_btn {
                    width: 125px;
                    border-radius: 50px;
                    height: 44px;
                    background-color: #000000;
                    color: #dcff00;
                    margin-top: 147px;
                }
            }
            .actionRight_box {
                position: relative;
                .action_img {
                    width: 560px;
                }
                .play_icon {
                    width: 48px;
                    height: 48px;
                    cursor: pointer;
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                }
            }
            @media only screen and (max-width: 1400px) {
                width: 100%;
                padding: 100px 40px;
            }
            @media only screen and (max-width: 1200px) {
                width: 100%;
                padding: 80px 0px;
                .actionRight_box {
                    .action_img {
                        width: auto;
                        height: 369px;
                        // aspect-ratio: 1;
                    }
                }
                .actionLeft_box {
                    .video_btn {
                        margin-top: 50px;
                    }
                }
            }
        }
        .projectTextBox {
            font-family: NeuethingSansTest-SemiBoldUltraExpanded;
            font-weight: normal;
            font-size: 112px;
            color: #ffffff;
            line-height: 132px;
            text-align: left;
            margin: 110px 0 147px 0;
        }
        .projectTipBox {
            font-family: NeuethingSansTest-RegularExpanded;
            font-weight: normal;
            font-size: 28px;
            color: #ffffff;
            line-height: 43px;
            text-align: left;
            max-width: 955px;
        }
        .joinNardoBox {
            margin: 302px 0 147px 0;
            width: 100%;
            // height: 532px;
            background: url('../../../assets/images/website/rectangle.png') no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 20px;
            // aspect-ratio: 1362/532;
            justify-content: space-between;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 54px;
            align-items: end;
            .joinNardo-left {
                width: 546px;
                padding-left: 40px !important;
                .apparel_tit {
                    font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                    font-weight: normal;
                    font-size: 47px;
                    color: #ffffff;
                    line-height: 56px;
                    padding-top: 150px;
                }
                .apparel_tip {
                    font-family: NeuethingSansTest;
                    font-weight: 400;
                    font-size: 18px;
                    color: #ffffff;
                    line-height: 28px;
                    text-align: left;
                    margin-top: 42px;
                }
            }
            .joinNardo-right {
                display: flex;
                height: 100%;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                margin-top: 375px;
                padding-right: 100px !important;
                .join_btn_ft {
                    width: 165px;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    margin-right: 5px;
                    border-radius: 12px;
                }
                .joinMini-tip {
                    margin-top: 30px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #8f8f8f;
                    line-height: 24px;
                    text-align: right;
                    .join-link {
                        text-decoration: underline;
                        cursor: pointer;
                        margin-left: 5px;
                    }
                }
            }
            @media only screen and (max-width: 1400px) {
                margin: 202px 0 147px 0;
                .joinNardo-left {
                    .apparel_tit {
                        padding-top: 100px;
                    }
                }
                .joinNardo-right {
                    margin-top: 325px;
                }
            }
            @media only screen and (max-width: 1100px) {
                margin: 202px 0 147px 0;
                position: relative;
                padding: 40px 20px;
                .joinNardo-left {
                    width: 85% !important;
                    padding-left: 32px !important;
                    .apparel_tit {
                        padding-top: 100px;
                    }
                }
                .joinNardo-right {
                   padding-right: 0 !important;
                   width: 80% !important;
                   margin: 0 auto;

                }
            }
        }
    }
}
.mb-main {
    .home-page {
        overflow: hidden;
        background-color: #000000;
        // background-image: url('../../../assets/images/swiper-bg/Mobile-Background.png');
        background-image: none;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #000000;
        background-position-y: 90px;
        // aspect-ratio: 1441/5262;
        .content-box {
            padding: 0;
            min-height: 1000px;
            .content_newBox {
                padding: 0 16px;
            }
            .join_btn {
                width: 130px;
                border-radius: 50px;
                height: 48px;
            }
            .contentTop_box {
                padding: 0.24rem 0 1.87rem 0;
                justify-content: space-between;
                position: absolute;
                left: 16px;
                width: calc(100% - 32px);
                .logo {
                    width: 173px;
                }
                .join_btn_top {
                    display: none;
                }
            }
            .contentTop_textView {
                display: block;
                padding: 0;
                margin: 0 auto;
                width: 100%;
                .topLeft_img {
                    width: 1.52rem;
                    margin-left: 13px;
                    margin-bottom: 0;
                }
                .contentTop_textRight {
                    position: relative;
                    width: 100%;
                    padding: 0 16px;
                    box-sizing: border-box;
                    margin-top: -20px;
                    right: initial;
                    bottom: initial;
                    .top-tit {
                        font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                        font-weight: normal;
                        font-size: 16px;
                        color: #ffffff;
                        line-height: 29px;
                        text-align: left;
                    }
                    .top-tip {
                        width: 307px;
                        font-family: NeuethingSansTest-RegularExpanded;
                        font-weight: normal;
                        font-size: 14px;
                        color: #a6a6a6;
                        line-height: 27px;
                        text-align: left;
                        margin: 10px 0 32px 0;
                    }
                    .join_btn {
                        width: 100%;
                        border-radius: 12px;
                    }
                }
            }
            .top-clubsBox {
                margin-top: 0.85rem;
                .club_tip {
                    text-align: center;
                    font-family: NeuethingSansTest-Meduim;
                    font-weight: 400;
                    font-size: 8px;
                    color: #ffffff;
                    line-height: 20px;
                    margin-bottom: 0.2rem;
                }
                .clubs_img {
                    width: 53px;
                    height: 53px;
                    margin-right: 15px;
                }
            }
            .works-box {
                margin-top: 0.85rem;
                padding-bottom: 0.64rem;
                border-bottom: 1px solid #333333;
                .feature_text {
                    font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                    font-weight: normal;
                    font-size: 11px;
                    color: #dcff00;
                    line-height: 13px;
                    letter-spacing: 1px;
                    text-align: left;
                }
                .works_tit {
                    font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                    font-weight: normal;
                    font-size: 37px;
                    color: #ffffff;
                    line-height: 56px;
                    text-align: left;
                    margin: 12px 0 16px 0;
                }
                .works_tip {
                    font-family: NeuethingSansTest-RegularExpanded;
                    font-weight: normal;
                    font-size: 20px;
                    color: #a6a6a6;
                    line-height: 29px;
                    text-align: left;
                }
                .worksItemBox {
                    display: block;
                    width: 100%;
                    margin-top: 0;
                    padding: 0;
                    .worksItem {
                        width: 100%;
                        margin-top: 0.78rem;
                        &:nth-child(2) {
                            margin-left: 0;
                            margin-right: 0;
                        }
                        .works_img {
                            width: 100%;
                            height: auto;
                        }
                        .workStep_text {
                            padding: 0 11px;
                        }
                        .works_step {
                            font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                            font-weight: normal;
                            font-size: 24px;
                            color: #ffffff;
                            line-height: 33px;
                            text-align: left;
                            margin-top: 0.36rem;
                            .stepNum_box {
                                text-align: center;
                                background: #000000;
                                border-radius: 50%;
                                border: 1px solid #232323;
                                color: #dcff00;
                                font-family: NeuethingSansTest-MediumExpanded;
                                margin-right: 20px;
                            }
                        }
                        .works_tips {
                            margin-top: 0.19rem;
                            font-family: NeuethingSansTest;
                            font-weight: 400;
                            font-size: 18px;
                            color: #878787;
                            line-height: 28px;
                            text-align: left;
                            .AssistantLogo_img {
                                width: 54px;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
            .actionBox {
                display: block;
                width: 100%;
                padding: 0.4rem 0 0.4rem 0;
                margin: 0 auto;
                border-bottom: 1px solid #333333;
                .actionLeft_box {
                    display: none;
                }
                .actionRight_box {
                    position: relative;
                    .action_img {
                        width: 100%;
                        height: auto;
                    }
                    .play_icon {
                        width: 48px;
                        height: 48px;
                        cursor: pointer;
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                    }
                }
                .action_tit_mb {
                    font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                    font-weight: normal;
                    font-size: 16px;
                    color: #fdfdfd;
                    line-height: 29px;
                    text-align: left;
                    margin-top: 28px;
                }
                .action_tip_mb {
                    font-family: NeuethingSansTest;
                    font-weight: normal;
                    font-size: 18px;
                    color: #878787;
                    line-height: 28px;
                    text-align: left;
                    margin-top: 12px;
                    font-weight: 400;
                }
            }
            .projectTextBox {
                font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                font-weight: normal;
                font-size: 37px;
                color: #ffffff;
                line-height: 56px;
                text-align: left;
                margin: 0.8rem 0 0.42rem 0;
            }
            .projectTipBox {
                font-family: NeuethingSansTest;
                font-weight: normal;
                font-size: 18px;
                color: #878787;
                line-height: 28px;
                text-align: left;
            }
            .joinNardoBox {
                margin: 0.94rem 0 0.64rem 0;
                width: 100%;
                height: auto;
                background: url('../../../assets/images/website/rectangle-mobile.png') no-repeat;
                background-size: cover;
                border-radius: 20px;
                padding: 0.4rem 0.2rem;
                box-sizing: border-box;
                display: block;
                .joinNardo-left {
                    width: 100%;
                    .apparel_tit {
                        font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                        font-weight: normal;
                        font-size: 16px;
                        color: #ffffff;
                        line-height: 29px;
                        padding-top: 0;
                        letter-spacing: 1px;
                    }
                    .apparel_tip {
                        font-family: NeuethingSansTest;
                        font-weight: 400;
                        font-size: 18px;
                        color: #f5fbca;
                        line-height: 28px;
                        text-align: left;
                        margin-top: 0.16rem;
                    }
                }
                .joinNardo-right {
                    position: static;
                    display: block;
                    height: auto;
                    font-size: 16px;
                    margin-top: 22px;
                    .join_btn_ft {
                        width: 100%;
                        margin: 0.32rem 0 0 0;
                    }
                    .joinMini-tip {
                        margin-top: 30px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #8f8f8f;
                        line-height: 24px;
                        text-align: left;
                        .join-link {
                            text-decoration: underline;
                            cursor: pointer;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}

.policy-main {
    background-image: none;
    .contentTop_box {
        position: relative !important;
    }
    .textContent-box {
        width: 768px;
        color: #ededed;
        font-size: 18px;
        line-height: 28px;
        font-family: NeuethingSansTest-RegularExpanded;
        word-break: break-word;
        padding-bottom: 96px;
        .fz16 {
            font-size: 16px;
            color: #dcff00;
            text-align: center;
            line-height: 24px;
            font-family: NeuethingSansTest-SemiBoldExpanded;
        }
        .fz20 {
            font-size: 20px;
            text-align: center;
            line-height: 30px;
            font-family: NeuethingSansTest-RegularExpanded;
        }
        .fz-title {
            font-family: NeuethingSansTest-SemiBoldUltraExpanded;
            letter-spacing: -0.2px;
            font-weight: normal;
            font-size: 30px;
            color: #fdfdfd;
            line-height: 38px;
            text-align: left;
            margin: 40px 0 20px 0;
        }
        .fz48 {
            font-size: 48px;
            line-height: 60px;
            text-align: center;
            margin: 12px 0 24px 0;
        }
        a {
            color: #ededed;
            text-decoration: underline;
        }
    }
    .lineTextBox {
        background-color: #575757;
        height: 1px;
        width: 100%;
        margin-top: 97px;
    }
}
.team-main {
    .textContent-box {
        .fz-title {
            margin: 32px 0 16px 0;
        }
    }
}
.mb-main {
    .policy-main {
        background-image: none;
        padding-top: 64px;
        .contentTop_box {
            position: relative !important;
            display: none;
        }
        .textContent-box {
            padding: 0 16px;
            width: 100%;
            color: #ededed;
            font-size: 16px;
            line-height: 24px;
            font-family: NeuethingSansTest-RegularExpanded;
            word-break: break-all;
            .fz16 {
                font-size: 14px;
                color: #dcff00;
                text-align: center;
                line-height: 20px;
                font-family: NeuethingSansTest-SemiBoldExpanded;
            }
            .fz20 {
                font-size: 18px;
                text-align: center;
                line-height: 28px;
                font-family: NeuethingSansTest-RegularExpanded;
            }
            .fz-title {
                font-family: NeuethingSansTest-SemiBoldUltraExpanded;
                letter-spacing: -0.2px;
                font-weight: normal;
                font-size: 24px;
                color: #fdfdfd;
                line-height: 32px;
                text-align: left;
                margin: 40px 0 20px 0;
            }
            .fz48 {
                font-size: 36px;
                line-height: 44px;
                text-align: center;
                margin: 12px 0 24px 0;
            }
            a {
                color: #ededed;
                text-decoration: underline;
            }
        }
        .lineTextBox {
            background-color: #575757;
            height: 1px;
            width: 100%;
            margin-top: 97px;
        }
    }
}
