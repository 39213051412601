.not-found {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    background: black;

    .logo {
        display: flex;
        align-items: center;

        width: 100px;
        height: 100px;
        margin-right: 20px;
        padding-left: 30px;
        img {
            width: 173px;
            height: 30px;
        }
    }
    &-content {
        width: 784px;
        height: 608px;
        margin: 0 auto;
        padding: 118px 32px;
        margin-top: 80px;
        margin-bottom: 176px;
        .error-tips {
            font-family: SemiBold Expanded;
            font-weight: normal;
            font-size: 16px;
            color: #c5f600;
            line-height: 24px;
            margin-bottom: 12px;
        }
        .bold-tips {
            font-family: SemiBold UltraExpanded;
            font-size: 60px;
            color: #fdfdfd;
            line-height: 72px;
            font-weight: normal;
            margin-bottom: 24px;
        }
        .regular-tips {
            font-family: Regular Expanded;
            font-size: 20px;
            line-height: 30px;
            font-weight: normal;
            font-size: 20px;
            color: #ededed;
            line-height: 30px;
        }
        .not-found-btns {
            margin-top: 48px;
            .btn {
                width: 170px;
                height: 60px;
                border-radius: 16px;
                font-size: 12px;
                font-family: 'SemiBold ExtraExpanded';
                border: none;
                line-height: 20px;
                cursor: pointer;
                &-primary {
                    background: #c5f600;
                    color: #333;
                }
                &-secondary {
                    background: black;
                    color: white;
                    border: 1px solid #767676;
                    margin-right: 12px;
                }
            }
        }
    }
}
