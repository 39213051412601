@font-face {
    font-family: NeuethingSansTest;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-Regular.otf);
}
@font-face {
    font-family: NeuethingSansTest-Bold;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-Bold.otf);
}
@font-face {
    font-family: NeuethingSansTest-Meduim;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-Meduim.otf);
}
@font-face {
    font-family: NeuethingSansTest-SemiBold;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-SemiBold.otf);
}
@font-face {
    font-family: NeuethingSansTest-SemiBoldExpanded;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-SemiBoldExpanded.otf);
}
@font-face {
    font-family: NeuethingSansTest-SemiBoldUltraExpanded;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-SemiBoldUltraExpanded.otf);
}
@font-face {
    font-family: NeuethingSansTest-RegularExpanded;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-RegularExpanded.otf);
}
@font-face {
    font-family: NeuethingSansTest-MediumExpanded;
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-MediumExpanded.otf);
}

@font-face {
    font-family: 'Regular Expanded';
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-RegularExpanded.otf);
}
@font-face {
    font-family: 'SemiBold';
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-SemiBold.otf);
}
@font-face {
    font-family: 'SemiBold Expanded';
    src: url(./NeuethingFamilyTest/NeuethingFamilyTest-SemiBoldExpanded.otf);
}
