.footer-box {
    padding: 0 113px;
    color: #ededed;
    font-family: NeuethingSansTest-RegularExpanded;
    .footer-opView {
        box-sizing: content-box;
        align-items: flex-start;
        justify-content: space-between;
        height: 240px;
        padding: 64px 0;
        .footer-logoBox {
            color: #ededed;
            font-size: 16px;
            .footer-img {
                width: 142px;
                // height: 32px;
                margin-bottom: 32px;
            }
            .otherLink_box {
                margin-top: 40px;
                .otherLink_icon {
                    display: flex;
                    width: 36px;
                    height: 36px;
                    &:last-child {
                        margin-left: 32px;
                    }
                    .linin_icon,
                    .ins_icon {
                        // filter: invert(0.5) sepia(1) saturate(0) hue-rotate(180deg);
                        opacity: 0.5;
                        transition: all 0.2s;
                        &:hover {
                            // filter: brightness(0) saturate(100%) invert(100%);
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .footer_linkBox {
            margin-left: auto;
            align-items: flex-start;
            .linkBox {
                width: 144px;
                font-family: NeuethingSansTest-SemiBold;
                &:last-child {
                    margin-left: 32px;
                }
                .linkTopItem {
                    color: #d7d7d7;
                    font-size: 14px;
                    margin-bottom: 16px;
                }
                .linkItem {
                    font-size: 16px;
                    color: #ededed;
                    margin-bottom: 12px;
                }
            }
        }
        .sub_inputBox {
            margin-left: 32px;
            .sub_tip {
                color: #fdfdfd;
                font-size: 14px;
                font-family: NeuethingSansTest-MediumExpanded;
                margin-bottom: 16px;
            }
            .sub_input {
                height: 52px;
                border-radius: 16px;
                border: 1px solid rgba(255, 255, 255, 0.1);
                &::placeholder {
                    color: rgba(255, 255, 255, 0.5) !important;
                }
            }
            .sub_btn {
                margin-left: 16px;
                width: 156px;
                min-width: 156px;
                height: 52px;
                background: #dcff00;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border-radius: 16px 16px 16px 16px;
                border: 1px solid #dcff00;
            }
        }
    }
    .footer-lineBox {
        width: 100%;
        height: 1px;
        background: #515151;
    }
    .contact_box {
        margin-bottom: 12px;
        .emailLink_box {
            color: #d7d7d7;
            text-decoration: underline;
            margin-left: 5px;
            font-family: 'SemiBold Expanded';
        }
    }
    .footer-copyRight {
        padding: 32px 0 48px 0;
        color: #d7d7d7;
        font-family: 'Regular Expanded';
        font-size: 16px;
        line-height: 24px;
        .copy_linkView {
            text-decoration: none;
            margin-left: 16px;
            cursor: pointer;
            color: #d7d7d7;
            a {
                color: #d7d7d7;
                text-decoration: underline;
            }
        }
    }
}

.mb-main {
    .footer-box {
        padding: 0 16px;
        color: #ededed;
        font-family: NeuethingSansTest-RegularExpanded;
        .footer-opView {
            display: block;
            height: auto;
            padding: 0.48rem 0 0rem 0;
            .footer-logoBox {
                color: #ededed;
                font-size: 16px;
                .footer-img {
                    width: 142px;
                    // height: 32px;
                    margin-bottom: 0.24rem;
                }
            }
            .footer_linkBox {
                margin-left: auto;
                align-items: flex-start;
                .linkBox {
                    width: 1.57rem;
                    font-family: NeuethingSansTest-SemiBold;
                    &:last-child {
                        margin-left: auto;
                    }
                    .linkTopItem {
                        color: #d7d7d7;
                        font-size: 14px;
                        margin-bottom: 16px;
                    }
                    .linkItem {
                        font-size: 16px;
                        color: #ededed;
                        margin-bottom: 12px;
                    }
                }
            }
            .sub_inputBox {
                margin-left: 0;
                .sub_tip {
                    color: #fdfdfd;
                    font-size: 14px;
                    font-family: NeuethingSansTest-MediumExpanded;
                    margin-bottom: 0.16rem;
                    margin-top: 0.48rem;
                }
                .sub_input {
                    width: 100%;
                    height: 44px;
                    border-radius: 8px;
                    font-size: 14px;
                    &::placeholder {
                        font-size: 14px;
                    }
                }
                .sub_btn {
                    width: 100%;
                    height: 44px;
                    margin: 0.16rem 0 0.48rem 0;
                    border-radius: 12px;
                }
            }
        }
        .footer-lineBox {
            width: 100%;
            height: 1px;
            background: #515151;
        }
        .ft_btnBox {
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .footer-copyRight {
            padding: 32px 0 48px 0;
            color: #d7d7d7;
            font-family: 'Regular Expanded';
            font-size: 16px;
            line-height: 24px;
            .copy_linkViewBox{
                margin-left: 0 !important;
                .copy_linkView {
                    text-decoration: none;
                    margin-right: 20px;
                    margin-left: 0;
                    margin-bottom: 20px;
                    cursor: pointer;
                    color: #d7d7d7;
                    a {
                        color: #d7d7d7;
                        text-decoration: underline;
                    }
                }
            }
        
        }
        .emailLink_box {
            margin: 0 !important;
        }
    }
}
