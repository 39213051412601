/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-08 10:37:09
 * Copyright © Techne All rights reserved
 */
.r-left {
    display: flex;
    align-items: center;
}
.r-top {
    display: flex;
    align-items: flex-start;
}
.r-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.marLeft {
    margin-left: auto;
}
.r-input {
    width: 100%;
    height: 48px;
    background: #000000;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #2b2b2b;
    font-size: 14px;
    font-family: NeuethingSansTest-MediumExpanded;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    caret-color: #dcff00;
    &:hover,
    &:focus,
    &:focus-within {
        border: 1px solid #2b2b2b;
        background-color: #000000;
    }
    &::placeholder {
        font-size: 14px;
        font-family: NeuethingSansTest-MediumExpanded;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5) !important;
        line-height: 24px;
    }
    &:focus {
        color: #dcff00;
    }
    .ant-input {
        background: #000000;
        &::placeholder {
            font-size: 14px;
            font-family: NeuethingSansTest-MediumExpanded;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5) !important;
        }
        font-size: 14px !important;
        font-family: NeuethingSansTest-MediumExpanded;
        font-weight: 400;
        color: #ffffff;
        caret-color: #dcff00;
    }
    &.ant-input-disabled {
        background: #4d4d4d;
        border-color: #4d4d4d;
    }
}
.r-round-input {
    border-radius: 12px !important;
    padding-left: 20px;
}
.r-round-input.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    border-color: #d92d20 !important;
    background-color: #000000 !important;
    &:hover,
    &:focus,
    &:focus-within {
        border: 1px solid #2b2b2b !important;
        background-color: #000000 !important;
        border-color: #d92d20 !important;
    }
    // .ant-input {
    //     &::placeholder {
    //         font-size: 14px;
    //         font-family: var(--primary-light-font);
    //         font-weight: 400;
    //         color: #F04438 !important;
    //     }
    // }
}
.r-gray_btn,
.r-default_btn,
.r-defaultBlick_btn,
.r-defaultBlickNew_btn,
.r-primary_btn {
    position: static;
    z-index: 3;
    height: 44px;
    background: #dcff00;
    border-radius: 12px;
    opacity: 1;
    border: 1px solid #dcff00;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 12px;
    font-family: NeuethingSansTest-SemiBoldExpanded;
    letter-spacing: -0.5px;
    font-weight: normal;
    cursor: pointer;
    color: #000000;
    // box-shadow: none;
    &:hover {
        border: 1px solid #dcff00;
        color: #000000;
    }
}
.r-primary_btn {
    border: 1px solid #dcff00;
    color: #000000;
    background-color: #dcff00;
    &:disabled {
        border: 1px solid #dcff00;
        color: #000000;
        background-color: #dcff00;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #dcff00;
        color: #000000;
        background-color: #dcff00;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover {
        color: #000000 !important;
        border: 1px solid #dcff00 !important;
        background-color: #dcff00 !important;
    }
}
.r-primaryHollow_btn {
    border: 1px solid #dcff00;
    color: #dcff00;
    background-color: #000000;
    &:disabled {
        border: 1px solid #dcff00;
        color: #dcff00;
        background-color: #000000;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #dcff00;
        color: #dcff00;
        background-color: #000000;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover {
        color: #dcff00 !important;
        border: 1px solid #dcff00 !important;
        background-color: #000000 !important;
    }
}
.r-gray_btn {
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;
    background: #161616;
    border-radius: 6px;
    border: 1px solid #ff7957;
    color: #ff7957;
    &:hover {
        border-color: #ff7957;
        color: #ff7957;
    }
    &:disabled,
    &.disabled {
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: rgba(255, 255, 255, 0.7);
        background-color: #161616;
        cursor: not-allowed;
        &:hover {
            border: 1px solid rgba(255, 255, 255, 0.3) !important;
            color: rgba(255, 255, 255, 0.7) !important;
        }
    }
}
.loading_icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    background: url('../assets/images/loading.png') no-repeat;
    background-size: contain;
}
.err-tip {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('../assets/images/err-tip.png') no-repeat;
    background-size: contain;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.ant-spin .ant-spin-dot {
    animation-name: rotate;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.r-divider {
    color: rgba(255, 255, 255, 0.7) !important;
    margin: 24px 0 4px 0 !important;
    &::after,
    &::before {
        border-block-start-color: rgba(255, 255, 255, 0.7) !important;
    }
}
.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.emptyTetx {
    color: #ffffff;
    font-size: 14px;
    padding: 40px 0;
}
.home-nextBtn.disabled {
    opacity: 0.5;
}
.fullLoading-box {
    div.ant-spin {
        position: fixed;
        max-height: initial;
        z-index: 20;
    }
    div > .ant-spin .ant-spin-dot {
        margin: -20px;
    }
    .ant-spin-blur {
        opacity: 1;
        &::after {
            background-color: #000000;
            opacity: 0.7;
        }
    }
}
.r-modal {
    width: 800px !important;
    .ant-modal-content {
        border-radius: 12px !important;
        padding: 44px 20px 20px !important;
        box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
    }
    .r-cancel_btn {
        margin-right: 20px;
    }
    .ant-modal-close {
        top: 6px;
        right: 10px;
    }
    .ant-modal-header,
    .ant-modal-footer {
        margin: 0;
    }
    .dialogVideo-website {
        width: 100%;
    }
}
.mb-main {
    .r-modal {
        width: 3.42rem !important;
        .ant-modal-content {
            font-size: 16px;
            border-radius: 12px !important;
            padding: 38px 0.16rem 0.16rem !important;
            box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
        }
        .r-cancel_btn {
            margin-right: 0.14rem;
        }
        .ant-modal-close {
            top: 6px;
            right: 6px;
        }
        .r-cancel_btn,
        .r-primary_btn {
            height: 40px;
            font-size: 0.14rem;
            font-family: Inter-Medium;
        }
    }
}
